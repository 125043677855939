<template>
  <div class="mobilePage">
    <div
      class="headerBox1"
      v-if="shopIosDownUrl"
      @click="onDownload('', 'shopIos')"
    ></div>
    <div class="headerBox" v-else @click="onDownload(true)"></div>
    <van-tabs
      v-model="tabActive"
      title-active-color="#ffffff"
      title-inactive-color="#ffffff"
      animated
      :lazy-render="false"
      class="vantTabs"
    >
      <van-tab
        :title="item.label"
        :name="item.label"
        v-for="item in tabs"
        :key="item.value"
      >
        <div
          v-if="shopIosDownUrl"
          class="main"
          @click="onDownload('', 'shopIos')"
        >
          <div class="content1" v-if="item.value == 'hot'"></div>
          <div class="content2" v-if="item.value == 'darkweb'"></div>
          <div class="content3" v-if="item.value == 'cartoon'"></div>
        </div>
        <div v-else class="main" @click="onDownload()">
          <div class="content1" v-if="item.value == 'hot'"></div>
          <div class="content2" v-if="item.value == 'darkweb'"></div>
          <div class="content3" v-if="item.value == 'cartoon'"></div>
        </div>
      </van-tab>
    </van-tabs>
    <div class="floatingBox" v-if="isShowContactBox">
      <div class="telegram" v-if="cTg" @click="jumpUrl(cTg)"></div>
      <div class="potato" v-if="cPotato" @click="jumpUrl(cPotato)"></div>
      <div class="business" v-if="cBusiness" @click="jumpUrl(cBusiness)"></div>
      <div class="channel" v-if="cChannel" @click="jumpUrl(cChannel)"></div>
    </div>
    <div
      class="downloadIosAppStore"
      @click="onDownload('', 'shopIos')"
      v-if="shopIosDownUrl"
    ></div>
    <div
      class="bottomBox1"
      v-if="shopIosDownUrl"
      @click="onDownload('', 'shopIos')"
    >
      <div class="iosAppStoreBtn"></div>
      <div class="spareBtn" @click.stop="onDownload(true)"></div>
    </div>
    <div class="storeBagTips" v-if="shopIosDownUrl"></div>
    <div class="bottomBox" v-else @click="onDownload(true)"></div>
    <!-- 安卓提示框 -->
    <van-overlay :show="androidPromptShow" z-index="999" class="androidOverlay">
      <div>
        <div class="overlayContent" @click.stop>
          <div class="confirmBtnBox" @click="androidPromptShow = false"></div>
        </div>
      </div>
    </van-overlay>
    <!-- 苹果提示框 -->
    <van-overlay :show="iosPromptShow" z-index="999" class="iosOverlay">
      <div>
        <div class="overlayContent" @click.stop>
          <div class="confirmBtnBox" @click="iosPromptShow = false"></div>
        </div>
      </div>
    </van-overlay>
    <van-popup v-model="showTip" class="downTip">
      <div class="downTipTitle">温馨提示</div>
      <div class="downTipContent">
        请使用Safari浏览器进行下载
      </div>
      <div class="url">{{ currUrl }}</div>
      <div class="downTipConfirm" @click.stop="doCopy">复制链接</div>
    </van-popup>
  </div>
</template>

<script>
import "swiper/css/swiper.css";
import getApkInfo from "@/api/getApkInfo";

export default {
  props: ["os"],
  data() {
    return {
      androidPromptShow: false,
      iosPromptShow: false,
      showTip: false,
      currUrl: window.location.href,
      tabActive: "",
      tabs: [
        {
          label: "热门",
          value: "hot",
        },
        {
          label: "暗网",
          value: "darkweb",
        },
        {
          label: "禁品",
          value: "cartoon",
        },
      ],
      cTg: "",
      cPotato: "",
      cBusiness: "",
      cChannel: "",
      isShowContactBox: false,
      recordParam: "",
      shopIosDownUrl: "", // 商店包链接
    };
  },
  mounted() {
    let ua = this.os.userAgent.toLowerCase();
    let isSafari =
      ua.indexOf("safari") !== -1 &&
      ua.indexOf("browser") === -1 &&
      ua.indexOf("android") === -1;
    // alert(ua)
    console.log(ua);
    console.log(isSafari);
    // console.log(ua.indexOf('safari'), "------");
    if (!isSafari && this.os.isPhone) {
      this.showTip = true;
      // console.log(this.showTip, '-------------')
      // alert('请使用Safari浏览器进行下载')
    }
    const referrer = sessionStorage.getItem("referrer");
    const dc = sessionStorage.getItem("dc");
    if (referrer && dc) {
      this.currUrl = referrer + "user.html?dc=" + dc;
    } else {
      if (referrer) {
        this.currUrl = referrer + "user.html";
      } else {
        this.currUrl = window.location.href;
      }
    }
  },
  methods: {
    // 剪贴板
    doCopy() {
      this.$copyText(this.currUrl).then(
        () => {
          console.log("复制成功");
          this.showTip = false;
        },
        () => {
          console.log("复制失败");
        }
      );
    },
    async showInstallTutorial(type) {
      if (type == "ios") {
        this.iosPromptShow = true;
      } else {
        this.androidPromptShow = true;
      }
    },
    async onDownload(flag, iosType) {
      let type = "android";
      if (this.os.isPhone) {
        type = "ios";
        this.recordParam.terminal = "ios";
        this.recordParam.packageType = 1;
        getApkInfo(this.recordParam);
      } else {
        type = "android";
      }
      if (flag && !iosType) {
        await this.showInstallTutorial(type);
      }
      setTimeout(() => {
        this.$emit("getApkInfo", type, iosType);
      }, 500);
    },
    setShopIosDownUrl(shopIosDownUrl) {
      this.shopIosDownUrl = shopIosDownUrl;
    },
    setRecordParams(recordParam) {
      this.recordParam = recordParam;
    },
    setConfigs(configs) {
      if (!configs.length) return;
      this.isShowContactBox = true;
      configs.forEach((item) => {
        if (item.configType === "tg_group") {
          this.cTg = item.link;
        } else if (item.configType === "potato_group") {
          this.cPotato = item.link;
        } else if (item.configType === "sw_cooperate") {
          this.cBusiness = item.link;
        } else if (item.configType === "qd_cooperate") {
          this.cChannel = item.link;
        }
      });
    },
    jumpUrl(url) {
      window.open(url);
    },
  },
};
</script>

<style lang="scss" scoped>
.mobilePage {
  height: 100%;
  width: 100%;
  background-color: rgb(0, 0, 0);
  padding: 18px 16px 0;
  .headerBox {
    height: 64px;
    width: 100%;
    background: url("./../../../assets/images/mobile1/headerBox.webp") no-repeat;
    background-size: 100% 100%;
  }
  .headerBox1 {
    height: 64px;
    width: 100%;
    background: url("./../../../assets/images/mobile1/headerBox1.webp")
      no-repeat;
    background-size: 100% 100%;
  }
  .vantTabs {
    height: 100%;
    ::v-deep .van-tabs__wrap {
      height: 37px;
      margin-top: 12px;
      border-radius: 8px;
      .van-tab {
        height: 30px;
        font-size: 12px;
        border-radius: 6px;
      }
      .van-tab--active {
        background: #c91b00;
      }
      .van-tabs__nav {
        padding: 0 3.5px;
        background: rgb(45, 44, 47);
        align-items: center;
        justify-content: space-between;
      }
      .van-tabs__line {
        display: none;
      }
    }
    ::v-deep .van-tabs__content {
      height: 100%;
      .van-tabs__track {
        height: 100%;
        .van-tab__pane-wrapper {
          height: 100%;
          .van-tab__pane {
            height: 100%;
          }
        }
      }
    }
    .main {
      height: calc(100% - 190px);
      padding: 12px 0 80px;
      overflow-y: auto;
      .content1 {
        height: 742px;
        width: 343px;
        background: url("./../../../assets/images/mobile1/content1.webp")
          no-repeat;
        background-size: 100% 100%;
      }
      .content2 {
        height: 742px;
        width: 343px;
        background: url("./../../../assets/images/mobile1/content2.webp")
          no-repeat;
        background-size: 100% 100%;
      }
      .content3 {
        height: 742px;
        width: 343px;
        background: url("./../../../assets/images/mobile1/content3.webp")
          no-repeat;
        background-size: 100% 100%;
      }
    }
  }
  .floatingBox {
    border-radius: 28px;
    border: 1px solid #fff;
    background: #2d2c2f;
    width: 55px;
    position: fixed;
    right: 0;
    top: 187px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    padding: 30px 0;
    .telegram {
      height: 42px;
      width: 43px;
      background: url("./../../../assets/images/mobile1/telegram.png") no-repeat;
      background-size: 100% 100%;
    }
    .potato {
      height: 42px;
      width: 43px;
      background: url("./../../../assets/images/mobile1/potato.png") no-repeat;
      background-size: 100% 100%;
    }
    .business {
      height: 42px;
      width: 43px;
      background: url("./../../../assets/images/mobile1/business.png") no-repeat;
      background-size: 100% 100%;
    }
    .channel {
      height: 42px;
      width: 43px;
      background: url("./../../../assets/images/mobile1/channel.png") no-repeat;
      background-size: 100% 100%;
    }
  }
  .downloadIosAppStore {
    height: 36px;
    width: 284px;
    background: url("./../../../assets/images/mobile1/downloadIosAppStore.gif")
      no-repeat;
    background-size: 100% 100%;
    position: fixed;
    left: 0;
    bottom: 120px;
    z-index: 998;
  }
  .bottomBox {
    height: 73px;
    width: 343px;
    position: fixed;
    left: 50%;
    bottom: 16px;
    transform: translate(-50%, 0);
    background: url("./../../../assets/images/mobile1/bottomBg.webp") no-repeat;
    background-size: 100% 100%;
  }
  .bottomBox1 {
    height: 73px;
    width: 343px;
    position: fixed;
    left: 50%;
    bottom: 35px;
    transform: translate(-50%, 0);
    background: url("./../../../assets/images/mobile1/bottomBg1.webp") no-repeat;
    background-size: 100% 100%;
    .iosAppStoreBtn {
      height: 30px;
      width: 70px;
      background: url("./../../../assets/images/mobile1/iosAppStoreBtn.png")
        no-repeat;
      background-size: 100% 100%;
      position: absolute;
      top: 25px;
      right: 90px;
    }
    .spareBtn {
      height: 30px;
      width: 66px;
      background: url("./../../../assets/images/mobile1/spareBtn.png") no-repeat;
      background-size: 100% 100%;
      position: absolute;
      top: 25px;
      right: 16px;
    }
  }
  .storeBagTips {
    height: 25px;
    width: 100%;
    position: fixed;
    left: 0;
    bottom: 0px;
    background: url("./../../../assets/images/mobile1/storeBagTips.png")
      no-repeat;
    background-size: 100% 100%;
  }
  .androidOverlay {
    display: flex;
    align-items: center;
    justify-content: center;
    .overlayContent {
      width: 297px;
      height: 441px;
      background: url("./../../../assets/images/mobile1/android_tip.png")
        no-repeat;
      background-size: 100% 100%;
      border-radius: 12px;
      padding-top: 380px;
      .confirmBtnBox {
        height: 36px;
        width: 140px;
        border-radius: 40px;
        margin: 0 auto;
      }
    }
  }
  .iosOverlay {
    display: flex;
    align-items: center;
    justify-content: center;
    .overlayContent {
      width: 297px;
      height: 441px;
      background: url("./../../../assets/images/mobile1/iosTip.png") no-repeat;
      background-size: 100% 100%;
      border-radius: 12px;
      padding-top: 380px;
      .confirmBtnBox {
        height: 36px;
        width: 140px;
        border-radius: 40px;
        margin: 0 auto;
      }
    }
  }
}
.downTip {
  border-radius: 10px;
  padding: 18px;
  box-sizing: border-box;
  text-align: center;
  .downTipTitle {
    font-size: 18px;
    margin-bottom: 34px;
  }
  .downTipContent {
    white-space: nowrap;
    font-size: 15px;
  }
  .url {
    font-size: 15px;
    margin-top: 16px;
    text-align: left;
  }
  .downTipConfirm {
    // margin-top: 12px;
    font-size: 15px;
    width: 160px;
    height: 30px;
    border-radius: 40px;
    margin: 32px auto 0;
    color: #fff;
    background: #5b92ee;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
