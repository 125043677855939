<template>
  <div class="pcm-wrapper" id="pcm-wrapper">
    <div class="logo"></div>
    <div class="contactBox">
      <div class="telegram" v-if="cTg" @click="jumpUrl(cTg)"></div>
      <div class="potato" v-if="cPotato" @click="jumpUrl(cPotato)"></div>
      <div class="business" v-if="cBusiness" @click="jumpUrl(cBusiness)"></div>
      <div class="channel" v-if="cChannel" @click="jumpUrl(cChannel)"></div>
    </div>
    <div class="figureBox"></div>
    <div class="logoText"></div>
    <div class="downloadBox">
      <div class="qrcodeBox">
        <img class="qrcode" :src="qrImg" alt="" />
        <div class="mobileTip"></div>
      </div>
      <div class="ucTip"></div>
    </div>
  </div>
</template>

<script>
import autofit from "autofit.js";

autofit.init();
export default {
  props: ["qrImg"],
  data() {
    return {
      cTg: "",
      cPotato: "",
      cBusiness: "",
      cChannel: "",
    };
  },
  mounted() {
    autofit.init(
      {
        dh: 1080,
        dw: 1920,
        el: "#pcm-wrapper",
        resize: true,
      },
      false
    ); // 可关闭控制台运行提示输出
  },
  methods: {
    setConfigs(configs) {
      configs.forEach(item => {
        if (item.configType === "tg_group") {
          this.cTg = item.link;
        } else if (item.configType === "potato_group") {
          this.cPotato = item.link;
        } else if (item.configType === "sw_cooperate") {
          this.cBusiness = item.link;
        } else if (item.configType === "qd_cooperate") {
          this.cChannel = item.link;
        }
      });
    },
    jumpUrl(url) {
      window.open(url);
    },
  },
};
</script>

<style lang="scss" scoped>
.pcm-wrapper {
  width: 100vw;
  height: 100vh;
  background: url("./../../../assets/images/pc/bg.webp") no-repeat;
  background-size: 100% 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .logo {
    height: 102px;
    width: 325px;
    background: url("./../../../assets/images/pc/logo.png") no-repeat;
    background-size: 100% 100%;
    position: absolute;
    top: 67px;
    left: 164px;
  }
  .contactBox {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 98px;
    top: 59px;
    z-index: 999;
    .telegram {
      height: 68px;
      width: 68px;
      background: url("./../../../assets/images/pc/telegram.png") no-repeat;
      background-size: 100% 100%;
      margin-right: 24px;
      cursor: pointer;
    }
    .potato {
      height: 68px;
      width: 68px;
      background: url("./../../../assets/images/pc/potato.png") no-repeat;
      background-size: 100% 100%;
      margin-right: 24px;
      cursor: pointer;
    }
    .business {
      height: 68px;
      width: 68px;
      background: url("./../../../assets/images/pc/business.png") no-repeat;
      background-size: 100% 100%;
      margin-right: 24px;
      cursor: pointer;
    }
    .channel {
      height: 68px;
      width: 68px;
      background: url("./../../../assets/images/pc/channel.png") no-repeat;
      background-size: 100% 100%;
      cursor: pointer;
    }
  }
  .figureBox {
    height: 935px;
    width: 810px;
    position: absolute;
    right: 0px;
    bottom: 0px;
    background: url("../../../assets/images/pc/figure.webp") no-repeat;
    background-size: 100% 100%;
  }
  .logoText {
    height: 441px;
    width: 920px;
    position: absolute;
    left: 164px;
    top: 216px;
    background: url("../../../assets/images/pc/logoText.webp") no-repeat;
    background-size: 100% 100%;
  }
  .downloadBox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    left: 164px;
    bottom: 108px;
    .qrcodeBox {
      margin-right: 50px;
      display: flex;
      align-items: center;
      .qrcode {
        height: 250px;
        width: 250px;
        background-color: white;
        border: 5px solid rgb(186, 226, 252);
      }
      .mobileTip {
        height: 250px;
        width: 54px;
        background: url("./../../../assets/images/pc/mobileTip.webp") no-repeat;
        background-size: 100% 100%;
      }
    }
    .ucTip {
      height: 219px;
      width: 486px;
      background: url("./../../../assets/images/pc/ucTip.webp") no-repeat;
      background-size: 100% 100%;
    }
  }
}
</style>
